import PropTypes from "prop-types";
import { PureComponent } from "react";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isHomePageUrl } from "SourceUtil/Url";
import { isSignedIn } from "SourceUtil/Auth";
import { roundPrice } from "SourceUtil/Price";
import BrowserDatabase from "SourceUtil/BrowserDatabase";

import { fetchQuery } from "SourceUtil/Request";
import GoogleTagManagerQuery from "../../query/GoogleTagManager.query";
import { getActionField } from "../../eventData/actionField.data";
import {
  productAddToCart as productAction,
  productQtyChangeData,
} from "../../eventData/actionProduct.data";
import { baseProductData } from "../../eventData/baseProduct.data";
import {
  getCustomerEmail,
  getGeneralEventData,
  getLoginState,
  getPageType,
  getPageViewEventData,
  getPath,
  getProducts,
  getPurchaseProducts,
  getStoreView,
  routes,
} from "../../eventData/general.data";

import { getImpressionsData } from "../../eventData/impression.data";
import { getPromoEvent } from "../../eventData/promo.data";
import { setExecuted } from "../../store/GoogleTagManager/GoogleTagManager.action";
import {
  CHECKOUT,
  EVENT_SEARCH,
  NO_RESULTS_FOUND,
  RESULTS_LOADED,
  ROOT,
  SEARCH,
  SEARCH_CATEGORY,
  ZERO,
} from "./GoogleTagManager.config.js";
import {
  EVENT_GTM_CHECKOUT,
  EVENT_GTM_CHECKOUT_OPTION,
  EVENT_GTM_GENERAL_INIT,
  EVENT_GTM_PAGE_VIEW,
  EVENT_GTM_IMPRESSIONS_PLP,
  EVENT_GTM_IMPRESSIONS_SEARCH,
  EVENT_GTM_NOT_FOUND,
  EVENT_GTM_PRODUCT_ADD_TO_CART,
  EVENT_GTM_PRODUCT_CLICK,
  EVENT_GTM_PRODUCT_DETAIL,
  EVENT_GTM_PRODUCT_REMOVE_FROM_CART,
  EVENT_GTM_PURCHASE,
  EVENT_GTM_SITE_SEARCH,
  EVENT_GTM_SITE_SEARCH_STARTED,
  EVENT_GTM_PRODUCT_REMOVE_WISH_LIST,
  EVENT_GTM_USER_LOGIN,
  EVENT_GTM_USER_LOGIN_OUT,
  EVENT_GTM_USER_REGISTER,
  EVENT_GTM_GUEST_STEP,
  EVENT_KEY_ADD_TO_CART,
  EVENT_KEY_CHECKOUT,
  EVENT_KEY_CHECKOUT_OPTION,
  EVENT_KEY_GENERAL,
  EVENT_KEY_NOT_FOUND,
  EVENT_KEY_PRODUCT_CLICK,
  EVENT_KEY_PRODUCT_DETAIL,
  EVENT_KEY_PRODUCT_REMOVE_FROM_CART,
  EVENT_KEY_PURCHASE,
  EVENT_KEY_SEARCH,
  EVENT_KEY_SEARCH_STARTED,
  EVENT_KEY_USER_LOGIN,
  EVENT_KEY_USER_LOGIN_OUT,
  EVENT_KEY_USER_REGISTER,
  EVENT_KEY_VIEW_SIGNUP,
  IMPRESSIONS,
  EVENT_KEY_PAGE_VIEW,
  EVENT_GTM_PROMO_CLICK,
  EVENT_GTM_PROMO_VIEW,
  EVENT_KEY_PROMO_CLICK,
  EVENT_KEY_PROMO_VIEW,
  EVENT_GTM_VIEW_CART,
  EVENT_KEY_VIEW_CART,
  EVENT_GTM_PRODUCT_ADD_TO_WISH_LIST,
  EVENT_KEY_ADD_TO_WISH_LIST,
  EVENT_KEY_REMOVE_FROM_WISH_LIST,
} from "./GoogleTagManager.events";

/** @namespace GtmNew/Component/GoogleTagManager/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
  config: state.ConfigReducer.gtm,
  isRewriteLoading: state.UrlRewritesReducer.isLoading,
  storeConfig: state.ConfigReducer,
  isExecuted: state.GoogleTagManagerReducer.isExecuted,
  event: state.GoogleTagManagerReducer.event,
  events: state.ConfigReducer.gtm.events,
  customData: state.GoogleTagManagerReducer.custom,
  productsOnPages: state.ProductListReducer.pages,
  areProductsLoading: state.ProductListReducer.isLoading,
  signedIn: state.MyAccountReducer.isSignedIn,
  isLoading: state.ProductListInfoReducer.isLoading,
  cart: state.CartReducer.cartTotals,
  store: state,
});

/** @namespace GtmNew/Component/GoogleTagManager/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
  executed: () => dispatch(setExecuted()),
});

/** @namespace GtmNew/Component/GoogleTagManager/Container/GoogleTagManagerContainer */
export class GoogleTagManagerContainer extends PureComponent {
  static propTypes = {
    config: PropTypes.shape({
      enabled: PropTypes.bool,
      gtm_id: PropTypes.string,
    }),
    isRewriteLoading: PropTypes.bool.isRequired,
    isExecuted: PropTypes.bool.isRequired,
    event: PropTypes.string,
    events: PropTypes.shape({}),
    executed: PropTypes.func.isRequired,
    customData: PropTypes.any,
    location: PropTypes.any.isRequired,
  };

  static defaultProps = {
    config: {
      enabled: false,
      gtm_id: "",
    },
    event: EVENT_GTM_GENERAL_INIT,
    events: {},
    customData: "",
  };

  state = {
    isInitialized: false,
  };

  eventMap = {
    [EVENT_GTM_GENERAL_INIT]: {
      getData: this.prepareGeneralData.bind(this),
      eventKey: EVENT_KEY_GENERAL,
    },
    [EVENT_GTM_PAGE_VIEW]: {
      getData: this.preparePageView.bind(this),
      eventKey: EVENT_KEY_PAGE_VIEW,
    },
    [EVENT_GTM_USER_LOGIN]: {
      getData: this.prepareLoginState.bind(this),
      eventKey: EVENT_KEY_USER_LOGIN,
    },
    [EVENT_GTM_USER_LOGIN_OUT]: {
      getData: this.prepareLoginState.bind(this),
      eventKey: EVENT_KEY_USER_LOGIN_OUT,
    },
    [EVENT_GTM_USER_REGISTER]: {
      getData: this.blankEvent.bind(this),
      eventKey: EVENT_KEY_USER_REGISTER,
    },
    [EVENT_GTM_PRODUCT_ADD_TO_CART]: {
      getData: this.prepareAddToCartData.bind(this),
      eventKey: EVENT_KEY_ADD_TO_CART,
    },
    [EVENT_GTM_PRODUCT_REMOVE_FROM_CART]: {
      getData: this.prepareRemoveFromCartData.bind(this),
      eventKey: EVENT_KEY_PRODUCT_REMOVE_FROM_CART,
    },
    [EVENT_GTM_PRODUCT_CLICK]: {
      getData: this.prepareProductClickData.bind(this),
      eventKey: EVENT_KEY_PRODUCT_CLICK,
    },
    [EVENT_GTM_PRODUCT_DETAIL]: {
      getData: this.prepareProductDetailsData.bind(this),
      eventKey: EVENT_KEY_PRODUCT_DETAIL,
    },
    [EVENT_GTM_IMPRESSIONS_PLP]: {
      getData: this.prepareProductImpression.bind(this),
      eventKey: IMPRESSIONS,
    },
    [EVENT_GTM_IMPRESSIONS_SEARCH]: {
      getData: this.prepareProductSearchImpression.bind(this),
      eventKey: IMPRESSIONS,
    },
    [EVENT_GTM_NOT_FOUND]: {
      getData: this.prepareNotFound.bind(this),
      eventKey: EVENT_KEY_NOT_FOUND,
    },
    [EVENT_GTM_CHECKOUT_OPTION]: {
      getData: this.prepareCheckoutOption.bind(this),
      eventKey: EVENT_KEY_CHECKOUT_OPTION,
    },
    [EVENT_GTM_GUEST_STEP]: {
      getData: this.prepareCheckoutOption.bind(this),
      eventKey: EVENT_KEY_VIEW_SIGNUP,
    },
    [EVENT_GTM_CHECKOUT]: {
      getData: this.prepareCheckout.bind(this),
      eventKey: EVENT_KEY_CHECKOUT,
    },
    [EVENT_GTM_PURCHASE]: {
      getData: this.preparePurchase.bind(this),
      eventKey: EVENT_KEY_PURCHASE,
    },
    [EVENT_GTM_SITE_SEARCH]: {
      getData: this.prepareSearch.bind(this),
      eventKey: EVENT_KEY_SEARCH,
    },
    [EVENT_GTM_SITE_SEARCH_STARTED]: {
      getData: this.blankEvent.bind(this),
      eventKey: EVENT_KEY_SEARCH_STARTED,
    },
    [EVENT_GTM_PROMO_CLICK]: {
      getData: this.preparePromoEvent.bind(this),
      eventKey: EVENT_KEY_PROMO_CLICK,
    },
    [EVENT_GTM_PROMO_VIEW]: {
      getData: this.preparePromoEvent.bind(this),
      eventKey: EVENT_KEY_PROMO_VIEW,
    },
    [EVENT_GTM_VIEW_CART]: {
      getData: this.prepareViewCart.bind(this),
      eventKey: EVENT_KEY_VIEW_CART,
    },
    [EVENT_GTM_PRODUCT_ADD_TO_WISH_LIST]: {
      getData: this.prepareAddToCartData.bind(this),
      eventKey: EVENT_KEY_ADD_TO_WISH_LIST,
    },
    [EVENT_GTM_PRODUCT_REMOVE_WISH_LIST]: {
      getData: this.prepareRemoveFromCartData.bind(this),
      eventKey: EVENT_KEY_REMOVE_FROM_WISH_LIST,
    },
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentDidUpdate(prevProps) {
    const {
      isExecuted,
      isRewriteLoading,
      config: { enabled },
      store,
      location: { pathname },
      store: { CartReducer: { cartTotals = {} } = {} } = {},
      signedIn,
      event,
    } = this.props;

    const { isInitialized } = this.state;

    const {
      isRewriteLoading: prevRewriteLoading,
      location: { pathname: prevPath },
      store: {
        CartReducer: {
          cartTotals: prevCartTotals = {},
          cartTotals: { items: cartItems = [] } = {},
        } = {},
      } = {},
      signedIn: prevSignedIn,
      isExecuted: preIsExecuted,
    } = prevProps;

    if (
      isExecuted &&
      event === EVENT_GTM_PURCHASE &&
      isExecuted !== preIsExecuted
    ) {
      this.eventLogger();
    }

    const shouldFireGeneral =
      prevRewriteLoading && !isRewriteLoading && isInitialized && enabled;

    if (prevSignedIn !== signedIn) {
      this.pushEvent(
        this.prepareLoginState({
          event: signedIn ? EVENT_KEY_USER_LOGIN : EVENT_KEY_USER_LOGIN_OUT,
        })
      );
    }

    if (
      JSON.stringify(prevCartTotals) !== JSON.stringify(cartTotals) &&
      this.canFireGeneral() &&
      isInitialized &&
      enabled
    ) {
      setTimeout(() => {
        this.fireGeneralEvent();
      }, 2000);
    }

    const storeView = getStoreView(store);
    const path = getPath(pathname, storeView);

    // Init And General Event
    if (
      !isRewriteLoading &&
      (isSignedIn() || this.canFireGeneral()) &&
      !isInitialized &&
      enabled
    ) {
      this.init();
      setTimeout(() => {
        this.fireGeneralEvent();
        this.firePageViewEvent();
      }, 2000);
    }

    // General Event
    if (
      pathname !== prevPath &&
      (shouldFireGeneral || (isInitialized && this.canFireGeneral() && enabled))
    ) {
      setTimeout(() => {
        this.fireGeneralEvent();
        this.firePageViewEvent();
      }, 2000);
    }

    // If user Lands on one of React routes
    if (
      pathname !== prevPath &&
      !isInitialized &&
      enabled &&
      (!shouldFireGeneral || isHomePageUrl(pathname))
    ) {
      routes().find((route) => {
        if (path.includes(route)) {
          setTimeout(() => {
            this.firePageViewEvent();
            this.fireGeneralEvent();
          }, 2000);
          return null;
        }
      });
    }

    // Any event fire
    if (!isExecuted && isInitialized) {
      this.executeEvent();
    }
  }

  init() {
    const {
      config: { enabled, gtm_id },
    } = this.props;
    this.setState({ isInitialized: true });

    if (enabled) {
      TagManager.initialize({ gtmId: gtm_id });
    }
  }

  executeEvent() {
    const { events: parentEvent, event } = this.props;

    const events = {
      ...parentEvent,
      [EVENT_GTM_PROMO_CLICK]: true,
      [EVENT_GTM_PROMO_VIEW]: true,
      [EVENT_GTM_VIEW_CART]: true,
      [EVENT_GTM_PRODUCT_ADD_TO_WISH_LIST]: true,
      [EVENT_GTM_PRODUCT_REMOVE_WISH_LIST]: true,
      [EVENT_GTM_USER_LOGIN]: true,
      [EVENT_GTM_USER_LOGIN_OUT]: true,
      [EVENT_GTM_GUEST_STEP]: true,
    };

    // Check if event exists in Event map and backend configuration
    if (this.eventExist(event) && events[event]) {
      const data = this.eventMap[event].getData();
      const { dataLayer: { ecommerce: nextEcommerce } = {} } = data;
      // issue with impression after applying filters
      const impressionsExist =
        nextEcommerce?.impressions &&
        dataLayer.some(
          ({ ecommerce = {} }) =>
            JSON.stringify(ecommerce?.impressions) ===
            JSON.stringify(nextEcommerce.impressions)
        );

      if (data?.dataLayer["ecommerce"]) {
        window.dataLayer.push({ ecommerce: null });
        //     dataLayer.filter(({ ecommerce = {} }, i) => {
        //         if (ecommerce && Object.keys(ecommerce).length > 0 && dataLayer[i]) {
        //             dataLayer.splice(i, i);
        //         }
        //     });
      }

      if (!impressionsExist) {
        this.pushEvent(data);
      }
    }
  }

  eventLogger() {
    const data = this.preparePurchase();
    if (data.dataLayer.event === EVENT_KEY_PURCHASE) {
      const query = GoogleTagManagerQuery.getEventLogger(JSON.stringify(data));
      fetchQuery(query);
    }
  }

  canFireGeneral(prevSignedIn, event) {
    const { cart = {} } = this.props;

    return !(Object.keys(cart).length === 0);
  }

  fireGeneralEvent() {
    const data = this.prepareGeneralData();

    if (data.dataLayer) {
      data.dataLayer.event = EVENT_KEY_GENERAL;
    }

    if (window.dataLayer.push({ cart: null })) {
      TagManager.dataLayer(data);
    }
  }

  firePageViewEvent() {
    const { store, executed } = this.props;

    if (!getPageType(store).pageType && !store?.MetaReducer?.title) {
      return null;
    }

    const data = this.preparePageView();

    TagManager.dataLayer(data);
    executed();
  }

  resize() {
    this.setState({ canFireImpression: true });
  }

  // Event Push
  pushEvent(data) {
    const { executed } = this.props;

    TagManager.dataLayer(data);
    // Changes redux state and tels that event is executed
    executed();
  }

  // Check if event exists in a map
  eventExist(event) {
    return typeof this.eventMap[event] !== "undefined";
  }

  prepareCheckoutOption() {
    const {
      customData: {
        products: items,
        option,
        step,
        isShipping = false,
        isPayment = false,
      },
    } = this.props;

    const products =
      items.length > 0
        ? {
            products: items.length ? getProducts(items, CHECKOUT) : [],
          }
        : "";

    const evenName = isPayment ? "add_payment_info" : this.getEventKey();

    return {
      dataLayer: {
        event: isShipping ? "add_shipping_info" : evenName,
        ecommerce: {
          checkout_option: {
            actionField: {
              option,
              step,
              action: CHECKOUT,
            },
            ...products,
          },
        },
      },
    };
  }

  preparePromoEvent() {
    const { customData } = this.props;

    return {
      dataLayer: {
        event: this.getEventKey(),
        ...getPromoEvent(this.getEventKey(), customData),
      },
    };
  }

  prepareCheckout() {
    const {
      customData: { step },
      cart: { items = [] },
    } = this.props;
    const products =
      items.length > 0
        ? {
            products: items.length ? getProducts(items, CHECKOUT) : [],
          }
        : "";

    return {
      dataLayer: {
        event: step !== 1 ? this.getEventKey() : "begin_checkout",
        ecommerce: {
          checkout: {
            actionField: {
              step,
              action: CHECKOUT,
            },
            ...products,
          },
        },
      },
    };
  }

  prepareViewCart() {
    const {
      customData: {
        totals: { items = [] },
      },
    } = this.props;

    return {
      dataLayer: {
        event: this.getEventKey(),
        ecommerce: {
          checkout: {
            products: items.length ? getProducts(items, CHECKOUT) : [],
          },
        },
      },
    };
  }

  prepareNotFound() {
    return {
      dataLayer: {
        event: this.getEventKey(),
        eventAction: window.location.href,
      },
    };
  }

  prepareUserLoginData() {
    return {
      dataLayer: {
        event: this.getEventKey(),
      },
    };
  }

  prepareAddToCartData() {
    const {
      customData,
      storeConfig: {
        currencyData: { current_currency_code },
      },
    } = this.props;
    const { isCart = false, quantity = 1 } = customData;
    return {
      dataLayer: {
        event: this.getEventKey(),
        ecommerce: {
          currencyCode: current_currency_code,
          value:
            (!isCart
              ? productAction(customData)?.price
              : productAction(customData.product)?.price) * quantity,
          add: {
            products: [
              !isCart
                ? { ...productAction(customData) }
                : { ...productQtyChangeData(customData) },
            ],
          },
        },
      },
    };
  }

  prepareRemoveFromCartData() {
    const {
      customData,
      storeConfig: {
        currencyData: { current_currency_code },
      },
    } = this.props;
    const { isCart = false, quantity, qty = 1, product = {} } = customData;

    return {
      dataLayer: {
        event: this.getEventKey(),
        ecommerce: {
          currencyCode: current_currency_code,
          value: Math.abs(
            (!isCart
              ? productAction(customData)?.price
              : productAction({
                  product: product?.product ? product?.product : product,
                })?.price) * (quantity ? quantity : product?.qty)
          ),
          remove: {
            products: [
              !isCart
                ? { ...productAction(customData) }
                : { ...productQtyChangeData(customData) },
            ],
          },
        },
      },
    };
  }

  prepareProductClickData() {
    const {
      customData: { prevCategoryId, pathname, search = false, list },
      customData,
      store,
    } = this.props;

    return {
      dataLayer: {
        event: this.getEventKey(),
        ecommerce: {
          click: {
            actionField: getActionField(
              store,
              prevCategoryId,
              pathname,
              search,
              list
            ),
            products: [
              { ...baseProductData({ product: { ...customData } }, true) },
            ],
          },
        },
      },
    };
  }

  prepareProductDetailsData() {
    const { customData } = this.props;
    const { isCart = false } = customData;
    return {
      dataLayer: {
        event: this.getEventKey(),
        value: productAction({ product: customData, quantity: ZERO })?.price,
        ecommerce: {
          detail: {
            products: [
              { ...baseProductData({ product: { ...customData } }, true) },
            ],
          },
        },
      },
    };
  }

  blankEvent() {
    return {
      dataLayer: {
        event: this.getEventKey(),
      },
    };
  }

  prepareLoginState(event) {
    return {
      dataLayer: {
        ...event,
        ...getLoginState(),
      },
    };
  }

  preparePageView() {
    const { store } = this.props;

    return {
      dataLayer: {
        event: EVENT_KEY_PAGE_VIEW,
        ...getPageViewEventData(store),
      },
    };
  }

  prepareGeneralData() {
    const { store } = this.props;

    return {
      dataLayer: {
        event: EVENT_KEY_GENERAL,
        ...getGeneralEventData(store),
      },
    };
  }

  preparePurchase() {
    const {
      customData: {
        orderID,
        email,
        revenue,
        tax,
        telephone,
        products: items = [],
        discount: discount_amount,
        shipping,
        coupon,
        orderShippingMethod,
        orderPaymentMethod,
        currencyCode: quote_currency_code,
      } = {},
      store,
    } = this.props;

    // const {
    //     customData: {
    //         orderID,
    //         email,
    //         purchase: {
    //             grand_total: revenue,
    //             tax_amount: tax,
    //             items,
    //             discount_amount,
    //             shipping_amount: shipping,
    //             coupon_code: coupon,
    //             selectedShippingMethod: orderShippingMethod,
    //             quote_currency_code,
    //             shippingAddress,
    //             shipping_method,
    //             paymentMethod

    //         }
    //         // orderPaymentMethod, orderShippingMethod, orderID, revenue, tax, shipping, coupon, products
    //     },
    //     store
    //     // storeConfig: { currencyData: { current_currency_code } }
    // } = this.props;

    // products.map((product) => {
    //     product.price = +roundPrice(product.price);
    //     return product;
    // });

    return {
      dataLayer: {
        event: this.getEventKey(),
        orderPaymentMethod,
        orderShippingMethod,
        // phone: shippingAddress?.telephone || '',
        // orderPaymentMethod: paymentMethod,
        // orderShippingMethod: shipping_method,
        currency: quote_currency_code,
        value: +roundPrice(revenue),
        email: email || getCustomerEmail(store)?.CustomerEmail,
        phone: telephone || "",
        CookieConsent: BrowserDatabase.getItem("CookieConsent") || "",
        ecommerce: {
          currencyCode: quote_currency_code,
          method: "Form",
          value: +roundPrice(revenue),
          purchase: {
            actionField: {
              id: orderID,
              revenue: +roundPrice(revenue),
              tax: +roundPrice(tax),
              coupon: coupon === null ? "" : coupon,
              shipping: +roundPrice(shipping),
              coupon_discount_amount: +roundPrice(discount_amount),
              affiliation: "Online",
            },
            products:
              items.length && items.length > 0
                ? [...getPurchaseProducts(items)]
                : [],
            // products: items.length ? getProducts(items, EVENT_KEY_PURCHASE) : []
          },
        },
      },
    };
  }

  prepareProductImpression() {
    const {
      customData,
      storeConfig: {
        currencyData: { current_currency_code },
      },
      store,
      location: { pathname },
    } = this.props;

    return {
      dataLayer: {
        event: this.getEventKey(),
        ecommerce: {
          currencyCode: current_currency_code,
          impressions: getImpressionsData(customData, store),
          SearchResults: customData?.products?.length,
        },
      },
    };
  }

  prepareProductSearchImpression() {
    const {
      customData: { products = [], search },
      overlay = false,
    } = this.props;
    const siteSearch = this.prepareSearch(search, products.length);
    TagManager.dataLayer(siteSearch);

    return this.prepareProductImpression();
  }

  prepareSearch(search, resultLoaded) {
    return {
      dataLayer: {
        event: EVENT_KEY_SEARCH,
        eventCategory: EVENT_KEY_SEARCH,
        eventAction: !resultLoaded ? NO_RESULTS_FOUND : RESULTS_LOADED,
        eventLabel: search,
        eventNonInteraction: ZERO,
        SearchResults: resultLoaded,
      },
    };
  }

  // gets event Key aka Event Name in dataLayer
  getEventKey() {
    const { event = "" } = this.props;

    return this.eventMap[event].eventKey;
  }

  render() {
    return null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GoogleTagManagerContainer));
