import ProductWishlistButton from 'SourceComponent/ProductWishlistButton';
import AddToCart from 'SourceComponent/AddToCart';
import { PRODUCTLIST_POPUP, MULTITAB_POPUP } from '../utils/wishlist.config'
import { showPopup } from 'SourceStore/Popup/Popup.action';
import {
    CONFIGURABLE
} from 'SourceUtil/Product';
import {
    FASHION_SET,
    PIM_FASHION_ACCESSORIES,
    PIM_FASHION,
    PIM_FASHION_SIZE
} from "../../../../../src/component/ProductAttributeSet/ProductAttributeSet.config";

import {
    EVENT_GTM_PRODUCT_CLICK
} from '@mageguide/gtm-new/src/component/GoogleTagManager/GoogleTagManager.events';
import { event } from '@mageguide/gtm-new/src/store/GoogleTagManager/GoogleTagManager.action';
import getStore from 'SourceUtil/Store';
import {
    SAMPLE_CATEGORY, 
    BAG_CATEGORY, 
    CARDS_CATEGORY
} from 'Route/ProductPage/ProductPage.config';

const containerFunctions = (args, instance) => {
    return {
        ...args,
        showProductPopup: showProductPopup.bind(args, instance)
    }
}

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    
    return {
        ...callback.apply(instance, args),
        showPopup: (type, payload) => dispatch(showPopup(type, payload))
    }
}

const renderSampleAddToCart = (instance) => {
    const {
        product,
        product: {
            type_id,
            options = [],
            configurable_options: confOptions = {},
            stock_item
        },
        configurableVariantIndex,
        layout,
        maxSampleAdded,
        isSampleProducts
    } = instance.props;

    const quantity = 1;
    const groupedProductQuantity = {};

    const requiredOptions = options.reduce((acc, { option_id, required }) => {
        if (required) {
            acc.push(option_id);
        }

        return acc;
    }, []);

    const productOptionsData = {
        requiredOptions
    };
    
    return (
        <AddToCart
          product={ product }
          configurableVariantIndex={ configurableVariantIndex }
          mix={ { block: 'ProductActions', elem: 'AddToCart' } }
          quantity={ quantity }
          groupedProductQuantity={ groupedProductQuantity }
          productOptionsData={ productOptionsData }
          disabled={ !stock_item?.in_stock || maxSampleAdded }
          layout={ layout }
          isSampleProducts= { isSampleProducts }
        />
    );
}

const renderAddToCart = (args, callback, instance) => {
    const {
        showProductPopup,
        layout,
        product: {
            attribute_set_id,
            stock_item,
            stock_status,
            type_id,
            sku
        },
        product,
        additionalClass,
        position,
        isSampleProducts = false,
        isSearchPage,
        isRecentViewedProduct,
        isLoading,
        isProductPage
    } = instance.props;
    
    const addTCartText = __('ADD TO CART');
    
    if (isSearchPage || isRecentViewedProduct)
        return null

    if (isSampleProducts) {
        return renderSampleAddToCart(instance);
    }

    if (
        attribute_set_id === FASHION_SET ||
        attribute_set_id === PIM_FASHION_ACCESSORIES ||
        attribute_set_id === PIM_FASHION ||
        attribute_set_id === PIM_FASHION_SIZE
    ) {
        return null;
    }

    if (!isLoading && (isProductPage || !stock_item?.in_stock || position || (stock_status !== 'IN_STOCK' && type_id === 'simple')) && !isSampleProducts) {
        const title = position || (stock_item?.in_stock && stock_status === 'IN_STOCK') || (isProductPage && type_id !== 'simple') ? addTCartText : __("Notify back in stock");
        // const isDisable = categories?.filter(({ id })=> id == BAG_CATEGORY || id == SAMPLE_CATEGORY || id == CARDS_CATEGORY);

        return instance.renderCardLinkWrapper(
            <button
                rel='nofollow'
                disabled={isLoading}
                block="Button AddToCart"
                mods={{ layout }}
                className={stock_item?.in_stock ? '' : 'outofstock'}
            >
                {isLoading ? __('ADD TO CART') : title}
            </button>
        );
    }

    if(additionalClass === 'HeroBrandPage__Single_Product_block') {
        return (
            <button
                disabled= { isLoading }
                block="Button AddToCart"
                mods={ { layout } }
                onClick={ () => showProductPopup(true) }
            >
                { addTCartText }
            </button>
        );
    }

    return (
        <button
          disabled = { isLoading }
          block="Button AddToCart"
          mods={ { layout } }
          onClick={ () => showProductPopup(true) }
        >
            { addTCartText }
        </button>
    );

}

const showProductPopup = (instance, args) => {
    const { showPopup, product: { name, sku, id }, isMultiTab, tabId, product, title, additionalClass = '', widgetId } = instance.props;

    const isGiftPL = additionalClass.indexOf('GiftBox_') !== -1 ? 'GiftBox_' : title ? title : widgetId;
    const popupId = isMultiTab ? `${MULTITAB_POPUP}-${tabId}` : !title && !additionalClass ? 
                        PRODUCTLIST_POPUP : `${PRODUCTLIST_POPUP}-${isGiftPL}`;

    const { dispatch } = getStore();

    dispatch(event(EVENT_GTM_PRODUCT_CLICK, {
        ...product
    }));

    return showPopup(
        popupId,
        {
            isMultiTab: isMultiTab || false,
            title: name,
            sku,
            id,
            isAddtoCart: args || false
        }
    );
}

const containerProps = (args, callback, instance) => {
    const { 
        isMultiTab, 
        tabId = '', 
        position = '', 
        isSampleProducts,
        isSearchPage,
        isRecentViewedProduct,
        maxSampleAdded,
        type = '',
        title,
        widgetId
    } = instance.props;

    return {
        ...callback.apply(instance, args),
         widgetId,
        title,
        isMultiTab: isMultiTab ? true : false,
        tabId,
        type,
        position,
        isSampleProducts,
        isSearchPage,
        isRecentViewedProduct,
        maxSampleAdded
    };
}


const renderProductCardWishlistButton = (args, callback, instance) => {
    const {
        product,
        product: {
            type_id,
            options = [],
            configurable_options: confOptions = {}
        },
        position,
        hideWishlistButton,
        isWishlistEnabled,
        configurableVariantIndex,
        isMultiTab,
        tabId,
        type,
        isLoading,
        title,
        widgetId
    } = instance.props;

    if (hideWishlistButton || !isWishlistEnabled) {
        return null;
    }


    const quantity = 1;
    const groupedProductQuantity = {};

    const requiredOptions = options?.reduce((acc, { option_id, required }) => {
        if (required) {
            acc.push(option_id);
        }

        return acc;
    }, []);
    
    const productOptionsData = {
        requiredOptions
    };

    return (
        <ProductWishlistButton
            widgetId={ widgetId }
            isLoading= { isLoading }
            title= { title }
            type= { type }
            position={ position }
            isProductList
            tabId ={ tabId }
            isMultiTab= { isMultiTab }
            product={product}
            quantity={quantity}
            configurableVariantIndex={configurableVariantIndex}
            // onProductValidationError={ onProductValidationError }
            productOptionsData={productOptionsData}
            groupedProductQuantity={groupedProductQuantity}
        />
    );
}

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderProductCardWishlistButton,
            renderAddToCart
        }
    },
    'Component/ProductCard/Container': {
        'member-function': {
            containerFunctions,
            containerProps
        },
        'function': {
            showProductPopup
        }
    },
    'Component/ProductCard/Container/mapDispatchToProps': {
        function: {
            position: 101,
            implementation: mapDispatchToProps
        }
    },
}