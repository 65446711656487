
import { addRichData } from './RichData.action'
import { BUNDLE } from 'SourceUtil/Product';
import greekUtils from 'greek-utils';
import getStore from 'SourceUtil/Store';

export class RichDataDispatcher {
    addRichdata = (data, dispatch) => {
        this.richdataType(data, dispatch);
    }

    initailizeScriptType = (data, dispatch) => {
        const { name, dataMapping } = data;
        const dataToMap = JSON.stringify(dataMapping);


        const updateRichDataInDOM = document.getElementById(name);
        let isExist = updateRichDataInDOM ? true : false;

        if (document.body && !isExist) {
            const addRichDataToDOM = document.createElement('script');
            addRichDataToDOM.setAttribute('type', 'application/ld+json')
            addRichDataToDOM.id = name;
            addRichDataToDOM.innerHTML = dataToMap;
            document.body.appendChild(addRichDataToDOM);
            dispatch(addRichData(name, isExist = true));
        } else {
            updateRichDataInDOM.innerHTML = dataToMap;
        }

    }

    richdataType(data, dispatch) {
        switch (data.name) {
            case 'pdp':
                return this.productRichdata(data, dispatch);
            case 'category':
                return this.categoryRichdata(data, dispatch);
            case 'faq':
                return this.faqRichData(data, dispatch);
            case 'post':
                return this.articleRichData(data, dispatch);
            case 'default':
                return this.homepageRichdata(data, dispatch);
            default:
                return '';
        }
    }

    productRichdata(data, dispatch) {
        if (!data) {
            return null;
        }

        const {
            product,
            product: {
                sku,
                attributes: { brand: { attribute_value, attribute_options = {} } = {} } = {},
                variants = [],
                rating_summary = {},
                review_count = {},
                name: productName,
                description: { html: description = {} },
                short_description: { html: short_description = {} },
                media_gallery_entries = [],
                small_image: { url } = {},
                configurableVariantIndex
            },
            name
        } = data;

        const BrandName = attribute_options[attribute_value]?.label || '';
        const productPrice = this.getProductPrice(product);
        const FinalPrice = productPrice.minimum_price ? productPrice.minimum_price.final_price.value : null;
        const RegularPrice = productPrice.minimum_price ? productPrice.minimum_price.regular_price.value : null;
        const price = FinalPrice && RegularPrice > FinalPrice ? FinalPrice : RegularPrice || '';
        const productOrVariant = variants.length > 0 && configurableVariantIndex > -1 ? variants[configurableVariantIndex] : product;
        const currency = productPrice ? productPrice.minimum_price.regular_price.currency : 'EUR';
        const regex = /(<([^>]+)>)/ig;
        const reviewData = product.reviews ? product.reviews : null;
        const { stock_status } = productOrVariant;

        let dataMapping = {
            "@context": "http://schema.org",
            "@type": "Product",
            "name": productName,
            "sku": sku,
            "description": description.replace(regex, '') || short_description.replace(regex, ''),
            ...this.getMediaData(media_gallery_entries, url),
            ...this.renderReview(reviewData, rating_summary),
            "offers": [
                {
                    "@type": "Offer",
                    "priceCurrency": currency,
                    "price": productPrice ? price : '',
                    "url": window.location.href,
                    "availability": `https://schema.org/${this.renderStock(stock_status)}`,
                    "itemCondition": "http://schema.org/NewCondition",
                    "seller": {
                        "@type": "Organization",
                        "url": window.location.origin,
                        "name": window.location.host
                    }
                }
            ]
        };

        // brand
        if (BrandName) {
            dataMapping['brand'] = {
                "@type": "Brand",
                "name": BrandName
            };
        }

        return this.initailizeScriptType({ name, dataMapping }, dispatch);
    };

    renderReview(reviewData, rating_summary) {
        if (rating_summary === null || rating_summary == 0) {
            return null
        }
        const percent = parseFloat(5 * (rating_summary || 0) / 100).toFixed(2);
        return {
            "review": {
                "@type": "Review",
                "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": percent,
                    "bestRating": "5"
                },
                "author":
                    reviewData.length ?
                        this._createReviewList(reviewData)
                        : null
            }
        }
    }

    getMediaData(mediaData, url) {
        const mediaMapped = [];
        if (!mediaData.length > 0) {
            return {
                "image": url
            };
        }
        mediaData.map((mediafile) => {
            mediaMapped.push(
                mediafile.file || '',
            )
        });
        return {
            "image": mediaMapped
        };
    }

    _createReviewItem({ nickname }) {

        let review = {
            // "aggregateRating": {
            //     "@type": "AggregationRating",
            //     "ratingValue":"",
            //     "reviewCount": ""
            // },
            "@type": "Person",
            "name": nickname
        }

        return review
    }

    _createReviewList(reviewData) {
        return reviewData.map((nickname) => this._createReviewItem(
            nickname
        ));
    }

    _getCategoryBreadcrumbs(category) {
        const { url = '', name = '', breadcrumbs } = category;
        const breadcrumbsList = [];

        if (breadcrumbs) {
            breadcrumbs
                .sort((a, b) => a.category_level - b.category_level)
                .forEach((crumb) => {
                    const { category_url, category_name, category_is_active } = crumb;

                    // do not add link to inactive categories
                    if (category_is_active) {
                        breadcrumbsList.push({
                            name: category_name,
                            url: {
                                pathname: category_url,
                                state: { category: true }
                            }
                        });
                    } else {
                        breadcrumbsList.push({
                            url: '',
                            name: category_name
                        });
                    }
                });
        }

        return [
            { url, name },
            ...breadcrumbsList.reverse()
        ];
    }


    _createItem({ url, name }, i) {
        const id = url?.pathname ? window.location.origin + url.pathname : window.location.origin + url;

        let item = {
            "@type": "ListItem",
            "position": i,
            "name": name,
            "item": id
        }

        return item
    }

    _createItemList(breadcrumbs) {
        const path = location?.pathname.split('/');

        const breadcrumbsWithHome = [
            ...breadcrumbs,
            // Looks like a browser bug, temporary fixed with .toString()
            { url: `/${path[1]}`, name: __('Home').toString() }
        ];

        return breadcrumbsWithHome.map((_, i) => this._createItem(
            breadcrumbsWithHome[breadcrumbsWithHome.length - 1 - i], i
        ));
    }

    _createCollectionPage(category) {
        const path = `${window.location.origin}${category.url.startsWith('/') ? category.url : `/${category.url}`}`;

        return {
            "url": path,
            "name": category.name,
            "description": category.meta_description,
            "breadcrumb": path,
        }
    }

    categoryRichdata(data, dispatch) {
        if (!data.category) {
            return null;
        }
        const { name } = data;
        const breadcrumbs = this._getCategoryBreadcrumbs(data.category);

        let dataMapping = {
            "@context": "http:\/\/schema.org",
            "@graph": [
                {
                    "@type": "BreadcrumbList",
                    "itemListElement":
                        breadcrumbs.length
                            ? this._createItemList(breadcrumbs)
                            : this._createItem({}, 0)
                },
                {
                    "@type": ["CollectionPage"],
                    ...this._createCollectionPage(data.category)
                }
            ]
        }
        return this.initailizeScriptType({ name, dataMapping }, dispatch);
    }

    _searchAction() {
        const url = window.location;
        const { code: storeCode } = getStore().getState().ConfigReducer;

        return {
            "@context": "http:\/\/schema.org",
            "@type": "WebSite",
            "url": `${url.origin}/${storeCode}`,
            "potentialAction": {
                "@type": "SearchAction",
                "target": {
                    "@type": "EntryPoint",
                    "urlTemplate": `${url.origin}/${storeCode}/all/f/search/{search_term_string}`
                },
                "query-input": "required name=search_term_string"
            }
        }
    }

    homepageRichdata(data, dispatch) {
        const { name, logo } = data;
        const url = window.location;
        const { code: storeCode } = getStore().getState().ConfigReducer;

        let dataMapping = [
            {
                "@context": "http:\/\/schema.org",
                "@type": "Organization",
                "url": url.origin,
                "logo": logo,
                "image": logo,
                "sameAs": [
                    "https://www.facebook.com/HondosCenterOfficial",
                    "https://x.com/hondoscenter",
                    "https://www.instagram.com/hondoscenter/",
                    "https://www.youtube.com/user/HondosCenter1967",
                    "https://www.pinterest.com/hondoscenter",
                    "https://gr.linkedin.com/company/hondoscenter"
                ],
                "name": "Hondos Center",
                "description": storeCode.includes("en") ? "Discover your favorite Beauty Brands and start a wonderful journey of beauty, skin care, makeup in the ultimate shopping destination." : "Επώνυμα Καλλυντικά, Αρώματα, Προϊόντα Περιποίησης και είδη Fashion",
                "email": "info@hondoscenter.gr",
                "telephone": "+30-2160028002",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": storeCode.includes("en") ? "10 Stadiou & 4 Omirou Str." : "Σταδίου 10 & Ομήρου 4",
                    "addressLocality": storeCode.includes("en") ? "ATHENS" : "ΑΘΗΝΑ",
                    "addressRegion": storeCode.includes("en") ? "ATTICA" : "ATTIKH",
                    "postalCode": "10564",
                    "addressCountry": "GR"
                }
            },
            this._searchAction()
        ]

        return this.initailizeScriptType({ name, dataMapping }, dispatch);

    }

    getProductPrice(product) {
        const {
            variants = [], type_id,
            configurableVariantIndex,
            selectedBundlePrice
        } = product;

        const {
            price_range
        } = variants[configurableVariantIndex] || product;

        if (type_id === BUNDLE) {
            const { price_range: { minimum_price: { regular_price: { currency } } } } = product;
            const priceValue = { value: selectedBundlePrice, currency };

            return {
                minimum_price: {
                    final_price: priceValue,
                    regular_price: priceValue
                }
            };
        }

        return price_range;
    }

    faqRichData(data, dispatch) {
        const {
            name,
            faq
        } = data;
        const dataMapping = {
            "@context": "http:\/\/schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                faq.map(item => {
                    return {
                        "@type": "Question",
                        "name": item.question,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": item.answer
                        }

                    }
                })
            ]
        }
        return this.initailizeScriptType({ name, dataMapping }, dispatch)
    }

    renderStock(stockStatus) {
        if (stockStatus === 'OUT_OF_STOCK') {
            return 'OutOfStock';
        }

        return 'InStock';
    }

    _organizationData(logo) {
        const url = window.location;

        return {
            "@type": "Organization",
            "url": url.origin,
            "logo": logo,
        }
    }

    articleRichData(data, dispatch) {
        const url = window.location;
        const {
            name,
            post,
            logo
        } = data;
        const {
            meta_title,
            publish_time,
            update_time = "",
            meta_description,
            first_image,
            media_gallery
        } = post

        const { code: storeCode } = getStore().getState().ConfigReducer;

        const isMediaGalleryArray = Array.isArray(media_gallery);
        let mediaGallery = isMediaGalleryArray ?
            media_gallery.map(item => item.includes('/media/') ? item : `${url.origin}/media/${item}`)
            : [media_gallery.includes('/media/') ? media_gallery : `${url.origin}/media/${media_gallery}`];

        let publishDate = new Date(publish_time);
        var publicIsoDateTime = new Date(publishDate.getTime() - (publishDate.getTimezoneOffset() * 60000)).toISOString();
        var modifiedDate = new Date(update_time);
        var modifiedIsoDateTime = new Date(modifiedDate.getTime() - (modifiedDate.getTimezoneOffset() * 60000)).toISOString();
        let dataMapping = {
            "@context": "http:\/\/schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": url.href
            },
            "image": [first_image, ...mediaGallery],
            "headline": meta_title,
            "description": meta_description,
            "author": {
                "@type": "Person",
                "name": "HC Insider",
                "url": `${url.origin}/${storeCode}/hc-insider`
            },
            "publisher": {
                ...this._organizationData(logo)
            },
            "datePublished": publicIsoDateTime,
            "dateModified": modifiedIsoDateTime
        }

        return this.initailizeScriptType({ name, dataMapping }, dispatch);
    }
}

export default new (RichDataDispatcher)();
