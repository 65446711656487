import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import { formatCurrency, roundPrice } from 'Util/Price';
import { PERCENTAGE, FIXED } from '../component/BundlerSection/BundlerSection.conf';
import ConfigurationSelect from '../component/ConfigurationSelect';

const renderBundlerProductNormalPrice = (args, callback, instance) => {
    const {
        product: {
            price_range,
            type_id,
            isConfigurableBundler
        },
        product,
        forBundler,
        bundlerOptions,
        handleSelect,
        selectedOption,
        bundlerItems
    } = instance.props;

    if (!price_range) {
        return <TextPlaceholder />;
    }

    if (type_id === "configurable" && bundlerOptions && isConfigurableBundler) {
        return (
            <ConfigurationSelect
                handleSelect={handleSelect}
                selectOptions={bundlerOptions}
                selectedOption={selectedOption}
                product={product}
                bundlerItems={bundlerItems}
            />
        )
    }
    if (type_id === "configurable" && bundlerOptions && bundlerOptions.length === 1) {
        return (
            <ProductPrice
                price={price_range}
                forBundler={forBundler}
                selectedOption={selectedOption}
                mix={{ block: 'ProductCard', elem: 'Price' }}
            />
        )
    }
    return (
        <ProductPrice
            price={price_range}
            forBundler={forBundler}
            mix={{ block: 'ProductCard', elem: 'Price' }}
        />
    );

}

const renderBundlerDiscountedPrice = (args, callback, instance) => {
    const {
        product: {
            discount,
            discount_type,
            price_range: { minimum_price: { final_price: { value, currency } } }
        }
    } = instance.props;

    const priceString = formatCurrency(currency);

    if (!discount) {
        return instance.renderProductPrice();
    }

    if (discount_type === PERCENTAGE) {
        return (
            <>
                <p className="ProductPrice ProductCard-Price bundler_discount">
                    <ins>
                        {`${priceString}${roundPrice(value - value * discount / 100)}`}
                    </ins>
                </p>
            </>
        );
    }

    return (
        <>
            <p className="ProductPrice ProductCard-Price bundler_discount">
                {/*{value - discount }*/}
                <ins>
                    {`${priceString}${roundPrice(value - discount)}`}
                </ins>
            </p>
        </>
    );
}

const renderConfigurationDetails = (args, callback, instance) => {
    const {
        mainProduct = false,
        bundlerOptions,
        product = {},
    } = instance.props;

    if (mainProduct && mainProduct.sku !== product.sku) {
        const { configurable_options = {} } = mainProduct;
        const { attributes = {} } = product;
        const itemsConfigurations = Object.keys(configurable_options).reduce((acc, key) => {
            if (attributes && attributes[key])
                return [
                    ...acc,
                    {
                        ...attributes[key]
                    }
                ]
            return acc;
        }, [])
        if (itemsConfigurations.length > 0)
            return (
                <>
                    {itemsConfigurations.map(configuration =>
                        <p block="ProductCard" elem="Configuration">
                            {`${configuration.attribute_label}: ${configuration.attribute_options[configuration.attribute_value].label}`}
                        </p>
                    )}
                </>
            )
    }

    if (bundlerOptions && bundlerOptions.length === 1 && !product.isConfigurableBundler) {
        const { configurable_options = {}, variants = [] } = product;
        const productOrVariant = variants && variants.find(variant => variant.sku === bundlerOptions[0].sku)
        const { attributes = {} } = productOrVariant || product;
        const itemsConfigurations = Object.keys(configurable_options).reduce((acc, key) => {
            if (attributes && attributes[key])
                return [
                    ...acc,
                    {
                        ...attributes[key]
                    }
                ]
            return acc;
        }, [])
        if (itemsConfigurations.length > 0)
            return (
                <>
                    {itemsConfigurations.map(configuration =>
                        <p block="ProductCard" elem="Configuration">
                            {`${configuration.attribute_label}: ${configuration.attribute_options[configuration.attribute_value].label}`}
                        </p>
                    )}
                </>
            )
    }

    return null

}

const renderCardContent = (args, callback, instance) => {
    const { forBundler } = instance.props;

    if (forBundler) {
        return (
            <>
                <div
                    block="ProductCard"
                    elem="Link"
                >
                    {instance.renderFigureLinkWrapper((
                        <figure block="ProductCard" elem="Figure">
                            {instance.renderPicture()}
                        </figure>
                    ))}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {instance.renderContentLinkWrapper((
                            <>
                                {instance.renderAdditionalProductDetails()}
                                {instance.renderMainDetails()}
                                {instance.renderConfigurationDetails()}
                                {/*{ instance.renderBundlerDiscountedPrice() }*/}
                                {/*{ instance.renderProductPrice() }*/}
                            </>
                        ))}
                        {instance.renderBundlerProductNormalPrice()}
                    </div>
                </div>
            </>
        );
    }
    return callback.apply(instance, args);

}

const shouldComponentUpdate = (args, callback, instance) => {
    const {
        product,
        device,
        productOrVariant,
        parameters,
        layout,
        quantity,
        maxSampleAdded,
        onHover,
        selectedOption,
        forBundler
    } = instance.props;

    const {
        product: nextProduct,
        device: nextDevice,
        productOrVariant: nextProductOrVariant,
        parameters: nextParameters,
        layout: prevLayout,
        quantity: prevQuantity,
        maxSampleAdded: prevMaxSampleAdded,
        onHover: preOnHover,
        selectedOption: prevSelectedOption
    } = args[0];

    return product !== nextProduct
        || device !== nextDevice
        || productOrVariant !== nextProductOrVariant
        || parameters !== nextParameters
        || quantity !== prevQuantity
        || layout !== prevLayout
        || maxSampleAdded !== prevMaxSampleAdded
        || onHover !== preOnHover
        || (forBundler && prevSelectedOption !== selectedOption)
}

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderCardContent: renderCardContent,
            renderBundlerDiscountedPrice: renderBundlerDiscountedPrice,
            renderBundlerProductNormalPrice: renderBundlerProductNormalPrice,
            shouldComponentUpdate: shouldComponentUpdate,
            renderConfigurationDetails: renderConfigurationDetails
        }
    }
};
