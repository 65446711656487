import { getIndexedProduct, CONFIGURABLE } from "Util/Product";
import { extendCartDataProducts } from "Util/Product/PerformaceProduct.js";
import { BUNDLER_PRODUCTS } from "../component/BundlerSection/BundlerSection.conf";

import ProductListQuery from "Query/ProductList.query";
import { updateNoMatch } from "Store/NoMatch/NoMatch.action";
import { updateProductDetails } from "Store/Product/Product.action";
import { QueryDispatcher } from "Util/Request";

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "Store/LinkedProducts/LinkedProducts.dispatcher"
);

export const onSuccess = (args, callback, instance) => {
    let data = args[0];
    let dispatch = args[1];
    const {
        products: { items },
    } = data;

    /**
     * In case there are no items, or item count is
     * smaller then 0 => the product was not found.
     */
    if (!items || items.length <= 0) {
        dispatch(updateNoMatch(true));

        return;
    }

    const [extendProduct] = extendCartDataProducts(items, true) || [];

    const product_links = items.reduce((links, product) => {
        const { product_links } = product;

        if (product_links) {
            Object.values(product_links).forEach((item) => {
                links.push(item);
            });
        }

        return links;
    }, []);

    const variantsProductLinks = items.reduce((variantsLinks, product) => {
        const { variants = [] } = product;

        const variantProductLinks = variants.reduce((variantLinks, product) => {
            const { product_links } = product.product;

            if (product_links) {
                Object.values(product_links).forEach((item) => {
                    if (item.link_type === BUNDLER_PRODUCTS)
                        variantLinks.push(item);
                });
            }

            return variantLinks;
        }, []);
        return [...variantsLinks, ...variantProductLinks];
    }, []);

    let combinedProductLinks = [...product_links, ...variantsProductLinks];

    LinkedProductsDispatcher.then(({ default: dispatcher }) => {
        if (combinedProductLinks.length > 0) {
            dispatcher.handleData(dispatch, combinedProductLinks);
        } else {
            dispatcher.clearLinkedProducts(dispatch);
        }
    });

    dispatch(updateProductDetails(extendProduct));
};

export default {
    "Store/Product/Dispatcher": {
        "member-function": {
            onSuccess: onSuccess,
        },
    },
};
