import { connect } from 'react-redux';
import { getProductInStock } from 'Util/Product/Extract';

import { ProductActionsContainer as SourceProductActionsContainer, mapStateToProps as sourceMapStateToProps, mapDispatchToProps } from 'SourceComponent/ProductActions/ProductActions.container';

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cartItems: state.CartReducer.cartTotals.items,
    attributesRedux: state.ConfigReducer,
});

export { mapDispatchToProps };

/** @namespace Component/ProductActions/Container */
export class ProductActionsContainer extends SourceProductActionsContainer {
    state = {
        quantity: 1,
        groupedProductQuantity: {},
        showShareButtons: false,
        chanelItems: 0,
        hasLimitation: false
    };

    containerFunctions = {
        showOnlyIfLoaded: this.showOnlyIfLoaded.bind(this),
        onProductValidationError: this.onProductValidationError.bind(this),
        getIsOptionInCurrentVariant: this.getIsOptionInCurrentVariant.bind(this),
        setQuantity: this.setQuantity.bind(this),
        setShowShareButtons: this.setShowShareButtons.bind(this),
        clickOutsideShareButtons: this.clickOutsideShareButtons.bind(this),
        setGroupedProductQuantity: this._setGroupedProductQuantity.bind(this),
        clearGroupedProductQuantity: this._clearGroupedProductQuantity.bind(this),
        setRefs: this.setRefs.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this)
    };

    containerProps() {
        const {
            areDetailsLoaded,
            areReviewsEnabled,
            configurableVariantIndex,
            device,
            displayProductStockStatus,
            getLink,
            getSelectedCustomizableOptions,
            isWishlistEnabled,
            isPriceAlertEnabled,
            isInStockAlertEnabled,
            parameters,
            product,
            productOptionsData,
            productOrVariant,
            selectedBundlePrice,
            selectedBundlePriceExclTax,
            selectedInitialBundlePrice,
            selectedLinkPrice,
            setBundlePrice,
            setLinkedDownloadables,
            setLinkedDownloadablesPrice,
            updateConfigurableVariant,
            reviewsRef,
            autoRelatedRef,
            brand,
            isProductPopup = false,
            cartItems,
            attributesRedux
        } = this.props;
        const { quantity, showShareButtons, hasLimitation } = this.state;

        return {
            areDetailsLoaded,
            areReviewsEnabled,
            configurableVariantIndex,
            device,
            displayProductStockStatus,
            getLink,
            getSelectedCustomizableOptions,
            isWishlistEnabled,
            isPriceAlertEnabled,
            isInStockAlertEnabled,
            parameters,
            product,
            productOptionsData,
            productOrVariant,
            selectedBundlePrice,
            selectedBundlePriceExclTax,
            selectedInitialBundlePrice,
            selectedLinkPrice,
            setBundlePrice,
            setLinkedDownloadables,
            setLinkedDownloadablesPrice,
            updateConfigurableVariant,
            quantity,
            showShareButtons,
            reviewsRef,
            autoRelatedRef,
            brand,
            isProductPopup,
            minQuantity: ProductActionsContainer.getMinQuantity(this.props),
            maxQuantity: ProductActionsContainer.getMaxQuantity(this.props),
            groupedProductQuantity: this._getGroupedProductQuantity(),
            productPrice: this.getProductPrice(),
            productName: this.getProductName(),
            offerCount: this.getOfferCount(),
            offerType: this.getOfferType(),
            stockMeta: this.getStockMeta(),
            metaLink: this.getMetaLink(),
            inStock: getProductInStock(product, configurableVariantIndex),
            cartItems,
            hasLimitation,
            attributesRedux
        };
    }
    componentDidMount() {
        const { product: { attributes = {} }, areDetailsLoaded } = this.props;

        if (areDetailsLoaded) {
            let limitation = attributes && attributes?.limitation?.attribute_value && attributes?.limitation?.attribute_value !== "0"
            if (limitation) {
                this.setState({ hasLimitation: limitation })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { product: { attributes = {} }, product: { sku }, areDetailsLoaded } = this.props;
        const { areDetailsLoaded: prevLoaded, product: { prevSku }, product: { attributes: prevAttributes = {} } } = prevProps;

        if (areDetailsLoaded !== prevLoaded || prevSku !== sku) {
            let prevLimitation = prevAttributes && prevAttributes?.limitation?.attribute_value && prevAttributes?.limitation?.attribute_value !== "0";
            let limitation = attributes && attributes?.limitation?.attribute_value && attributes?.limitation?.attribute_value !== "0";
            if (limitation !== prevLimitation) {
                this.setState({ hasLimitation: limitation })
            }
        }
    }

    setShowShareButtons() {
        const { showShareButtons } = this.state;
        this.setState({ showShareButtons: !showShareButtons });
    }

    clickOutsideShareButtons() {
        this.setState({ showShareButtons: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsContainer);
